import { css } from "lit";

export const animations = css`
  :host {
    --global-header-animation-duration: 0ms;
  }

  /******************
   ** DECLARATIONS **
   ******************/

  [data-animation-ready="true"] {
    --global-header-animation-duration: var(--duration-normal);
  }

  [data-animate],
  [data-submenu-open] {
    animation-duration: var(--global-header-animation-duration, 0ms);
    animation-timing-function: var(--ease-in-out);
    animation-fill-mode: both;
    animation-delay: var(--global-header-animation-delay, 0);
  }

  [data-animate="container"] {
    animation-name: container-out;
  }

  [data-nav-open="true"] [data-animate="container"] {
    animation-name: container-in;
  }

  [data-submenu-open="true"] [data-animate="menu"] {
    animation-name: main-menu-out;
  }

  [data-submenu-open="false"] [data-animate="menu"] {
    animation-name: main-menu-in;
  }

  [data-animate="submenu"] {
    animation-name: main-submenu-out;
  }

  [data-animate="submenu"]:not([inert]) {
    animation-name: main-submenu-in;
  }

  [data-animate="nav-item"] {
    animation-name: nav-item-out;
    animation-delay: var(--duration-normal);
  }

  [data-nav-open="true"] [data-animate="nav-item"] {
    animation-name: nav-item-in;
    animation-delay: var(--global-header-animation-delay);
  }

  [data-animate="util-item"] {
    transform-origin: 0 0;
    animation-name: util-item-out;
    animation-delay: var(--duration-normal);
  }

  [data-nav-open="true"] [data-animate="util-item"] {
    animation-name: util-item-in;
    animation-delay: var(--global-header-animation-delay);
  }

  [data-nav-open="true"] [data-submenu-open="true"] [data-animate="util-item"] {
    animation-name: util-item-out;
  }

  [data-animate="search"] {
    transition-property: opacity, transform;
    transition-duration: var(--duration-normal);
    transition-timing-function: var(--ease-in-out);
  }

  [data-nav-open="true"] [data-animate="search"] {
    opacity: 0;
    transform: scale(0.9);
  }

  [data-animate="logo"] bp-logo {
    transition-property: opacity, transform;
    transition-duration: var(--duration-normal);
    transition-timing-function: var(--ease-in-out);
  }

  [data-nav-open="true"] [data-animate="logo"] [variant="logo-mark"] {
    opacity: 0;
    transform: translate3d(-35px, 0, 0);
  }

  [data-nav-open="true"] [data-animate="logo"] [variant="word-mark"] {
    transform: translate3d(-35px, 0, 0);
    transition-delay: 30ms;
  }

  /*****************
   ** BREAKPOINTS **
   *****************/

  @media (min-width: 1024px) {
    [data-animate="container"],
    [data-animate="nav-item"],
    [data-animate="util-item"],
    [data-animate="menu"] {
      animation: none;
    }

    [data-animate="submenu"] {
      animation-delay: 0ms;
    }
  }

  /***************
   ** KEYFRAMES **
   ***************/

  @keyframes container-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes container-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes nav-item-in {
    0% {
      opacity: 0;
      transform: translate3d(var(--size-7), 0, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes nav-item-out {
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(var(--size-7), 0, 0);
    }
  }

  @keyframes main-menu-in {
    0% {
      opacity: 0;
      transform: translate3d(
        var(--global-header-menu-tx-closed),
        var(--global-header-menu-ty-closed),
        0
      );
    }

    100% {
      opacity: 1;
      transform: translate3d(
        var(--global-header-menu-tx-opened),
        var(--global-header-menu-ty-opened),
        0
      );
    }
  }

  @keyframes main-menu-out {
    0% {
      opacity: 1;
      transform: translate3d(
        var(--global-header-menu-tx-opened),
        var(--global-header-menu-ty-opened),
        0
      );
    }

    100% {
      opacity: 0;
      transform: translate3d(
        var(--global-header-menu-tx-closed),
        var(--global-header-menu-ty-closed),
        0
      );
    }
  }

  @keyframes main-submenu-in {
    0% {
      opacity: 0;
      transform: translate3d(
        var(--global-header-submenu-tx-closed),
        var(--global-header-submenu-ty-closed),
        0
      );
    }

    100% {
      opacity: 1;
      transform: translate3d(
        var(--global-header-submenu-tx-opened),
        var(--global-header-submenu-ty-opened),
        0
      );
    }
  }

  @keyframes main-submenu-out {
    0% {
      opacity: 1;
      transform: translate3d(
        var(--global-header-submenu-tx-opened),
        var(--global-header-submenu-ty-opened),
        0
      );
    }

    100% {
      opacity: 0;
      transform: translate3d(
        var(--global-header-submenu-tx-closed),
        var(--global-header-submenu-ty-closed),
        0
      );
    }
  }

  @keyframes util-item-in {
    0% {
      opacity: 0;
      transform: translate3d(0, var(--size-7), 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes util-item-out {
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(0, var(--size-7), 0);
    }
  }
`;
