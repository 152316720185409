/**
 * Polyfills Declarative Shadow DOM if the browser lacks support.
 */
export function polyfillDeclarativeShadowDOM(element: Element) {
  const template = element.querySelector("template");

  if (template) {
    element.attachShadow({ mode: "open" });
    element.shadowRoot?.append(template.content);
    template.remove();
  }
}

/**
 * Locks scrolling on the document body.
 */
export function lockDocumentScrolling() {
  document.body.style.setProperty("overflow", "hidden");
  document.body.style.setProperty("overscroll-behavior", "none");
}

/**
 * Unlocks scrolling on the document body.
 */
export function unlockDocumentScrolling() {
  document.body.style.removeProperty("overflow");
  document.body.style.removeProperty("overscroll-behavior");
}
