import { LitElement, css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("bp-global-header-language-picker-label")
export class GlobalHeaderPromo extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    .label {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: start;
      justify-content: flex-start;
      gap: var(--size-1);
    }

    small {
      font-size: var(--font-size-xs);
      color: var(--color-text-secondary);
    }
  `;

  @property()
  alt? = undefined;

  render() {
    return html`
      <div class="label">
        <span ?aria-hidden="${!!this.alt}"><slot></slot></span>
        ${this.alt ? html` <small>${this.alt}</small> ` : nothing}
      </div>
    `;
  }
}
