import { LitElement, css, html } from "lit";
import { customElement, state } from "lit/decorators.js";

import type { CurrentUser } from "../../data/user";

@customElement("bp-global-header-user-avatar")
export class GlobalHeaderUserAvatar extends LitElement {
  static styles = css`
    :host {
      --user-avatar-size: 24px;
      --icon-size: var(--user-avatar-size);

      display: block;
    }

    .avatar {
      display: block;
      border: 0;
      padding: 0;
      width: var(--user-avatar-size);
      height: var(--user-avatar-size);
      overflow: hidden;
      border-radius: var(--radius-full);
      cursor: pointer;
    }

    .avatar img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  `;

  @state()
  userInfo: CurrentUser | null = null;

  setUserInfo(info: CurrentUser | null) {
    this.userInfo = info;
  }

  render() {
    if (this.userInfo?.avatar.image) {
      return html`
        <div class="avatar">
          <img src="${this.userInfo.avatar.image}" alt="" />
        </div>
      `;
    }

    return html` <bp-icon icon="circle-user"></bp-icon> `;
  }
}
