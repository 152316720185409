import { LitElement, css, html, nothing } from "lit";
import {
  customElement,
  property,
  query,
  queryAll,
  state,
} from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { getNextFocusIndex } from "../../utils/a11y";
import { MQL_LG } from "../../constants";
import { debounce } from "../../utils/debounce";
import { performSearch, type SearchResultData } from "./global-header.helpers";
import {
  SEARCH_CLOSE_EVENT_NAME,
  SEARCH_OPEN_EVENT_NAME,
} from "./global-header.constants";

@customElement("bp-global-header-search")
export class GlobalHeaderSearch extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    #search[data-active] {
      background-color: var(--global-header-background-color);
    }

    #search-bar {
      background-color: inherit;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      padding-inline: var(--size-3) var(--size-1-5);
      box-sizing: border-box;
      position: absolute;
      inset-inline-start: 0;
      inset-block-start: 0;
      width: 100%;
    }

    #search-bar[inert] {
      pointer-events: none;
      z-index: -1;
      visibility: hidden;
    }

    form {
      border-radius: var(--radius-full);
      background-color: var(--color-neutral-10);
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: var(--size-1);
      align-items: center;
      padding-block: var(--size-0-5);
      padding-inline: var(--size-2);
      height: var(--size-4);
    }

    form bp-icon {
      color: var(--color-neutral-40);
    }

    button {
      border: 0;
      border-radius: var(--radius-md);
      background: transparent;
      appearance: none;
      display: inline-flex;
      align-items: center;
      gap: var(--size-1);
      font-family: var(--font-sans);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-semibold);
      user-select: none;
      cursor: pointer;
      color: currentColor;
      align-items: center;
      justify-content: center;
      height: var(--size-7);
      padding-inline: var(--size-1-5);
    }

    button:disabled {
      filter: grayscale(100%);
      opacity: var(--opacity-50);
      pointer-events: none;
    }

    button[inert] {
      visibility: hidden;
    }

    input {
      appearance: none;
      background-color: transparent;
      border: 0;
      font-size: var(--font-size-md);
      line-height: var(--line-height-tight);
      min-width: 0;
      padding: 0;
    }

    input:focus {
      outline: none;
    }

    input::-webkit-search-cancel-button {
      appearance: none;
    }

    #results {
      display: grid;
      gap: var(--size-2);
      padding-block: var(--size-2);
    }

    #results .items a {
      text-decoration: none;
      color: currentColor;
    }

    #results ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    #results li {
      border-radius: var(--radius-md);
      cursor: pointer;
    }

    #results li[aria-selected="true"] {
      background-color: var(--color-neutral-05);
    }

    #items a {
      display: block;
      padding: var(--size-1) var(--size-2);
    }

    .label {
      text-transform: uppercase;
      letter-spacing: var(--letter-spacing-wide);
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-sm);
      color: var(--color-gray);
      margin: var(--size-1);
      margin-block-end: var(--size-2);
    }

    @media (min-width: 1024px) {
      #search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
      }

      #search-bar {
        position: absolute;
        padding-inline: 0;
      }

      #results {
        padding: 0;
      }

      button {
        padding: var(--size-1);
        height: auto;
      }

      bp-global-header-submenu {
        position: absolute;
        inset-inline-end: 0;
        inset-block-start: var(--size-5);
      }

      #close-button {
        display: none;
      }
    }

    @media (hover: hover) {
      #results li:hover {
        background-color: var(--color-neutral-05);
      }
    }
  `;

  #desktopMql = MQL_LG;

  @state()
  data: SearchResultData | null = null;

  @state()
  isActive = false;

  @state()
  useDesktopLayout = this.#desktopMql.matches;

  @property({ attribute: "label" })
  label = "";

  @property({ attribute: "placeholder" })
  placeholder = "";

  @property({ attribute: "label-close" })
  labelClose = "";

  @query("form")
  $form?: HTMLFormElement;

  @query("input")
  $input?: HTMLInputElement;

  @queryAll("#results li")
  $resultsListItems?: NodeListOf<HTMLElement>;

  @query("#search")
  $search?: HTMLElement;

  _activeItemIndex = -1;

  get activeItemIndex() {
    return this._activeItemIndex;
  }

  set activeItemIndex(newVal: number) {
    this._activeItemIndex = newVal;

    this.$resultsListItems?.forEach((x, i) => {
      if (i === newVal) {
        x.setAttribute("aria-selected", "true");
        x.focus();
      } else {
        x.removeAttribute("aria-selected");
      }
    });
  }

  get hasResults() {
    return this.data?.items.length;
  }

  constructor() {
    super();

    this.#desktopMql.addEventListener(
      "change",
      this.handleDesktopMediaQueryChange,
    );

    this.addEventListener("keydown", this.handleKeyDown as EventListener);
  }

  activate() {
    this.isActive = true;

    // Wait for inert attribute changes to apply
    this.updateComplete.then(() => {
      this.$input?.focus();
    });

    document.addEventListener("click", this.handleDocumentClick);
  }

  deactivate() {
    this.isActive = false;
    this.reset();
    document.removeEventListener("click", this.handleDocumentClick);
  }

  reset() {
    this.$form?.reset();
    this.clearResults();
  }

  clearResults() {
    this.activeItemIndex = -1;
    this.data = null;
  }

  open() {
    this.dispatchEvent(
      new CustomEvent(SEARCH_OPEN_EVENT_NAME, {
        composed: true,
      }),
    );
  }

  close() {
    this.dispatchEvent(
      new CustomEvent(SEARCH_CLOSE_EVENT_NAME, {
        composed: true,
      }),
    );
  }

  async performSearch(query: string) {
    this.activeItemIndex = -1;
    this.data = await performSearch(query);
  }

  gotoResultsPage(query: string) {
    const href = `/search/#?cludoquery=${query}`;

    window.location.assign(href);
  }

  focusNextResult() {
    if (!this.data) return;

    this.activeItemIndex = getNextFocusIndex(
      "next",
      this.activeItemIndex,
      this.$resultsListItems?.length ?? 0,
    );
  }

  focusPrevResult() {
    if (!this.data) return;

    this.activeItemIndex = getNextFocusIndex(
      "prev",
      this.activeItemIndex,
      this.$resultsListItems?.length ?? 0,
    );
  }

  handleDesktopMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useDesktopLayout = matches;
  };

  handleCloseButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    this.close();
  };

  handleInput = debounce(() => {
    const value = this.$input?.value;

    if (value) {
      this.performSearch(value);
    } else {
      this.clearResults();
    }
  }, 250);

  handleSubmit = (e: SubmitEvent) => {
    e.preventDefault();

    const $item = this.$resultsListItems?.item(this.activeItemIndex);
    const href = $item?.querySelector("a")?.getAttribute("href");

    // Follow the link if the item is a link
    if (href) {
      window.location.assign(href);
      return;
    }

    const query = this.$input?.value;

    // Use the input value as the query for the search page
    if (query) {
      this.gotoResultsPage(query);
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Tab":
        this.activeItemIndex = -1;
        return;
      case "Escape":
        this.close();
        return;
      case "ArrowDown":
        e.preventDefault();
        this.focusNextResult();
        return;
      case "ArrowUp":
        e.preventDefault();
        this.focusPrevResult();
        return;
      default:
        return;
    }
  };

  handleDocumentClick = (e: MouseEvent) => {
    const shouldDeactivate = !e.composedPath().includes(this);

    if (shouldDeactivate) {
      this.deactivate();
    }
  };

  renderLinks() {
    if (!this.data?.items.length) {
      return nothing;
    }

    return html`
      <div class="items">
        <ul role="listbox">
          ${this.data?.items.map(
            (x) => html`
              <li role="option">
                <a href="${x.href}">${x.text}</a>
              </li>
            `,
          )}
        </ul>
      </div>
    `;
  }

  render() {
    return html`
      <div id="search" ?data-active=${this.isActive}>
        <div id="search-bar" ?inert=${!this.isActive}>
          <form @submit="${this.handleSubmit}">
            <bp-icon icon="magnifying-glass"></bp-icon>
            <input
              type="search"
              enterkeyhint="search"
              autocomplete="off"
              aria-autocomplete="list"
              aria-controls="results"
              aria-expanded=${this.hasResults}
              placeholder="${this.placeholder}"
              @input=${this.handleInput}
            />
          </form>

          <button
            aria-label="${this.labelClose}"
            id="close-button"
            @click=${this.handleCloseButtonClick}
          >
            <bp-icon icon="xmark"></bp-icon>
          </button>

          ${when(
            this.hasResults,
            () => html`
              <bp-global-header-submenu>
                <div id="results">
                  <div id="items">${this.renderLinks()}</div>
                </div>
              </bp-global-header-submenu>
            `,
          )}
        </div>

        <button
          aria-label=${this.label}
          id="open-button"
          ?inert=${this.isActive}
          @click=${this.open}
        >
          <bp-icon icon="magnifying-glass"></bp-icon>
        </button>
      </div>
    `;
  }
}
