import { get } from "../modules/env";
import { gql, query } from "../utils/client";

import type { ApiResponse } from "../types";

export interface CurrentUser {
  publicUserId: string;
  avatar: {
    image: string | null;
  };
}

/**
 * Returns the current user's info.
 */
export async function getCurrentUser(): Promise<CurrentUser | null> {
  const url = get("USER_API_URL") ?? globalThis.document.location.origin;

  return fetch(url, {
    credentials: "include",
  }).then(async (x) => await x.json());
}

/**
 * Adds the provided email address to a newsletter
 * distribution.
 */
export async function subscribeToNewsletter(
  email: string,
  types: string[],
  lang = "en",
) {
  const { data, errors } = await query<
    ApiResponse<{
      subscribeToNewsletters: boolean;
    }>
  >(
    {
      query: gql`
        mutation SubscribeToNewsletters($input: SubscribeToNewslettersInput!) {
          subscribeToNewsletters(input: $input)
        }
      `,
      variables: {
        input: {
          email: email,
          newsletterTypes: types,
        },
      },
    },
    {
      headers: {
        "Accept-Language": lang,
      },
    },
  );

  return data.subscribeToNewsletters && !errors?.length;
}
