/**
 * Determines whether the element is hidden.
 */
export function isHidden(el: HTMLElement): boolean {
  return (
    el.hasAttribute("hidden") ||
    (el.hasAttribute("aria-hidden") &&
      el.getAttribute("aria-hidden") !== "false") ||
    el.style.display === `none` ||
    el.style.opacity === `0` ||
    el.style.visibility === `hidden` ||
    el.style.visibility === `collapse`
  );
}

/**
 * Determines whether the element is disabled
 */
export function isDisabled(el: HTMLElement): boolean {
  return (
    el.hasAttribute("disabled") ||
    (el.hasAttribute("aria-disabled") &&
      el.getAttribute("aria-disabled") !== "false")
  );
}

/**
 * Determines whether an element is focusable.
 */
export function isFocusable(el: HTMLElement): boolean {
  if (el.getAttribute("tabindex") === "-1" || isHidden(el) || isDisabled(el)) {
    return false;
  }

  return (
    el.hasAttribute("tabindex") ||
    ((el instanceof HTMLAnchorElement || el instanceof HTMLAreaElement) &&
      el.hasAttribute("href")) ||
    el instanceof HTMLButtonElement ||
    el instanceof HTMLInputElement ||
    el instanceof HTMLTextAreaElement ||
    el instanceof HTMLSelectElement ||
    el instanceof HTMLIFrameElement
  );
}

/**
 * Get all of the focusable elements in a node's DOM tree.
 */
export function getFocusableElements(el: Element): HTMLElement[] {
  if (
    isFocusable(el as HTMLElement) &&
    !isDisabled(el as HTMLElement) &&
    !isHidden(el as HTMLElement)
  ) {
    return [el] as HTMLElement[];
  }

  if (el.shadowRoot) {
    return Array.from(el.shadowRoot.children).flatMap(getFocusableElements);
  }

  if (el instanceof HTMLSlotElement) {
    const assignedElements = el.assignedElements();

    if (assignedElements.length) {
      return assignedElements.flatMap(getFocusableElements);
    }
  }

  return Array.from(el.children).flatMap(getFocusableElements);
}

/**
 * Determines if an element has focusable elements in its shadow DOM
 */
export function hasFocusableChildren(element: HTMLElement) {
  return !!getFocusableElements(element).length;
}

/**
 * Get the index value of the next focusable item
 * in a set of focusable items (e.g. menu)
 */
export function getNextFocusIndex(
  direction: "prev" | "next",
  currentIndex: number,
  itemCount: number,
) {
  if (!itemCount) return 0;

  if (direction === "prev") {
    const nextIndex = currentIndex - 1;
    return nextIndex < 0 ? itemCount - 1 : nextIndex;
  }

  if (direction === "next") {
    const nextIndex = currentIndex + 1;
    return nextIndex < itemCount ? nextIndex : 0;
  }

  throw new Error("direction not supported.");
}
