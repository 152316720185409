/**
 * Cross-browser requestIdleCallback since it's not
 * supported by iOS Safari.
 * https://caniuse.com/requestidlecallback
 */
export function requestIdleCallback(callback: IdleRequestCallback) {
  if (typeof globalThis.requestIdleCallback === "function") {
    return globalThis.requestIdleCallback(callback, { timeout: 1000 });
  }

  const start = Date.now();

  return setTimeout(() => {
    callback({
      didTimeout: false,
      timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
    });
  }, 1);
}
