import { LitElement, css, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import {
  SUBMENU_CLOSE_EVENT_NAME,
  SUBMENU_OPEN_EVENT_NAME,
} from "./global-header.constants";
import { MQL_LG } from "../../constants";
import { getFocusableElements } from "../../utils/a11y";

@customElement("bp-global-header-submenu")
export class GlobalHeaderSubmenu extends LitElement {
  static styles = css`
    :host {
      --global-header-submenu-tx-opened: 0;
      --global-header-submenu-tx-closed: var(--size-5);
      --global-header-submenu-ty-opened: 0;
      --global-header-submenu-ty-closed: 0;

      display: block;
    }

    button {
      background: var(--global-header-background-color);
      border: none;
      width: 100%;
      padding: var(--size-1-5) var(--size-2-5);
      margin: 0;
      font: inherit;
      color: inherit;
      cursor: pointer;
      appearance: none;
      text-align: inherit;
      text-decoration: none;
      display: flex;
      width: 100%;
      align-items: center;
      position: sticky;
      inset-block-start: var(--size-5);
      margin-block-end: var(--size-0-5);
    }

    ::slotted(ul) {
      white-space: nowrap;
    }

    ::slotted(div[slot="footer"]) {
      margin-block-start: var(--size-2);
      position: relative;
    }

    @media (min-width: 1024px) {
      :host {
        --global-header-submenu-tx-opened: 0;
        --global-header-submenu-tx-closed: 0;
        --global-header-submenu-ty-opened: var(--size-5);
        --global-header-submenu-ty-closed: calc(
          var(--size-5) + var(--size-0-5)
        );
      }

      .submenu {
        min-width: var(--size-25);
        background-color: var(--color-white);
        border: 1px solid var(--color-neutral-10);
        border-radius: var(--radius-md);
        padding-block: var(--size-2);
        padding-inline: var(--size-1-5);
        box-shadow: var(--shadow-lg);
        position: relative;
        z-index: 0;
      }
    }
  `;

  #desktopMql = MQL_LG;

  @state()
  useDesktopLayout = this.#desktopMql.matches;

  @property({ attribute: "label-back" })
  labelBack: string = "";

  get $items() {
    return getFocusableElements(this);
  }

  constructor() {
    super();

    this.#desktopMql.addEventListener(
      "change",
      this.handleDesktopMediaQueryChange,
    );
  }

  focusFirstItem() {
    this.$items?.[0].focus();
  }

  open() {
    this.dispatchEvent(
      new CustomEvent(SUBMENU_OPEN_EVENT_NAME, {
        composed: true,
        detail: {
          id: this.id,
        },
      }),
    );
  }

  close() {
    this.dispatchEvent(
      new CustomEvent(SUBMENU_CLOSE_EVENT_NAME, {
        composed: true,
        detail: {
          id: this.id,
        },
      }),
    );
  }

  handleDesktopMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useDesktopLayout = matches;
  };

  render() {
    return html`
      <div class="submenu">
        ${!this.useDesktopLayout && this.labelBack
          ? html`
              <button @click="${this.close}" aria-label="${this.labelBack}">
                <bp-icon icon="angle-left"></bp-icon>
              </button>
            `
          : nothing}

        <nav>
          <slot></slot>
          <slot name="footer"></slot>
        </nav>
      </div>
    `;
  }
}
