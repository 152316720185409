import { css } from "lit";

import { animations } from "./global-header-animations.styles";

export const styles = css`
  :host {
    /* TODO Remove once color palette is updated */
    --color-surface-00: #ffffff;
    --color-interactive-secondary: #edeceb;
    --color-interactive-secondary-hover: #e0dfdf;
    --color-text-secondary: #767676;

    --global-header-background-color: var(--color-surface-00);
    --global-header-menu-tx-opened: 0;
    --global-header-menu-tx-closed: calc(-1 * var(--size-6));
    --global-header-menu-ty-opened: 0;
    --global-header-menu-ty-closed: 0;

    /* @link https://utopia.fyi/clamp/calculator?a=480,640,64—120 */
    --global-header-menu-inset-start: var(--size-10);
    /* @link https://utopia.fyi/clamp/calculator?a=480,640,0—10 */
    --global-header-menu-item-gap: clamp(0rem, -1.875rem + 6.25vh, 0.625rem);
    --global-header-submenu-item-gap: 0;

    display: block;
  }

  .bypass {
    padding: var(--size-2);
    display: block;
    text-align: center;
    color: inherit;
  }

  .bypass:not(:focus) {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  header {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-areas: "logo search control";
    grid-template-columns: 1fr;
    align-items: center;
    padding-inline: var(--size-1-5);
  }

  header[data-nav-open="true"]::before {
    content: "";
    background-color: var(--global-header-background-color);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: var(--z-80);
  }

  :is(a, button) {
    appearance: none;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    vertical-align: middle;
    gap: var(--size-1);
    background-color: transparent;
    border-radius: var(--radius-md);
    color: currentColor;
    border: none;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-normal);
    font-family: var(--font-sans);
    line-height: var(--line-height-none);
    -webkit-user-select: none;
    user-select: none;
    text-decoration: none;
    white-space: nowrap;
  }

  :is(a, button):focus-visible {
    outline-width: 3px;
    outline-style: solid;
    outline-color: var(--color-brand-accessible);
  }

  :is(a, button):focus:not(:focus-visible) {
    outline: none;
  }

  #languages a,
  #languages button,
  .util-menu a,
  .util-menu button {
    font-size: var(--font-size-md);
  }

  #menu-control a,
  #menu-control button {
    align-items: center;
    justify-content: center;
    padding-inline: var(--size-1-5);
    height: var(--size-7);
  }

  .menu-item {
    padding-block: var(--size-1-5);
    padding-inline: var(--size-3);
    border-radius: var(--radius-md);
    transition: background-color var(--duration-short) var(--ease-in-out);
  }

  .util-item {
    font-size: var(--font-size-md);
    padding-block: var(--size-1-5);
    padding-inline: var(--size-3);
    width: 100%;
  }

  .util-item > .util-item-ornament {
    display: none;
  }

  #container {
    background-color: var(--global-header-background-color);
    display: grid;
    grid-auto-flow: row;
    grid-template-areas:
      "primary"
      "languages"
      "user"
      "give";
    grid-template-rows: 1fr auto;
    z-index: var(--z-70);
    transform: translateZ(0);
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100dvw;
    height: 100dvh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #container .menu-item {
    width: 100%;
  }

  #logo {
    display: inline-flex;
    justify-self: start;
    grid-area: logo;
    z-index: var(--z-90);
    padding-block: var(--size-1-5);
    padding-inline: var(--size-1-5);
    line-height: var(--line-height-none);
  }

  #logo [variant="logo-mark"] {
    --logo-height: calc(var(--size-1) * 3.5);
    margin-block-start: 4px;
  }

  #logo [variant="word-mark"] {
    --logo-height: calc(var(--size-1) * 2);
    margin-block-start: 2px;
  }

  #primary-nav {
    grid-area: primary;
    align-self: start;
    padding-block: var(--global-header-menu-inset-start) var(--size-2);
  }

  #main-menu {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: var(--global-header-menu-item-gap);
    margin: 0;
  }

  #search {
    grid-area: search;
    z-index: var(--z-90);
  }

  #languages {
    --global-header-animation-delay: 30ms;
    grid-area: languages;
  }

  #give {
    --global-header-animation-delay: 90ms;
    grid-area: give;
    padding-block: var(--size-2) var(--size-3);
    padding-inline: var(--size-3);
  }

  #give > a {
    justify-content: center;
    width: 100%;
    background-color: var(--color-interactive-secondary);
  }

  #user-menu {
    --global-header-animation-delay: 60ms;
    grid-area: user;
  }

  #menu-control {
    grid-area: control;
    z-index: var(--z-80);
  }

  #menu-open-button {
    display: inline-flex;
  }

  [data-nav-open="true"] #menu-open-button {
    display: none;
  }

  #menu-close-button {
    display: inline-flex;
  }

  [data-nav-open="false"] #menu-close-button {
    display: none;
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: var(--global-header-menu-item-gap);
    padding: 0;
    margin: 0;
  }

  #languages-submenu ul,
  #user-submenu ul {
    gap: var(--global-header-submenu-item-gap);
  }

  bp-global-header-submenu {
    background-color: var(--color-white);
    position: absolute;
    z-index: var(--z-20);
    inset-block-start: 0;
    inset-inline-start: 0;
    box-sizing: border-box;
    width: 100dvw;
    height: 100dvh;
    padding-block: var(--global-header-menu-inset-start) var(--size-4);
  }

  @media (min-width: 1024px) {
    :host {
      --global-header-background-color: transparent;
      --global-header-menu-tx-opened: 0;
      --global-header-menu-tx-closed: 0;
      --global-header-menu-item-gap: var(--size-0-5);
      --global-header-submenu-item-gap: var(--size-0-5);
    }

    header {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: min-content 1fr;
      gap: var(--size-1);
    }

    header::before {
      display: none;
    }

    a,
    button {
      font-size: var(--font-size-md);
    }

    .menu-item {
      padding-block: var(--size-1);
      padding-inline: var(--size-1-5);
    }

    .util-item {
      padding-block: var(--size-1);
      padding-inline: var(--size-1);
    }

    .util-item > .util-item-label {
      display: none;
    }

    .util-item > .util-item-ornament {
      display: inline-flex;
    }

    #logo {
      padding-block: var(--size-2);
    }

    #container {
      grid-template-areas: "primary search languages give user";
      grid-template-columns: 1fr minmax(0, 360px) auto auto auto;
      align-items: center;
      column-gap: var(--size-1);
      position: static;
      opacity: 1;
      overflow: initial;
      width: initial;
      height: initial;
    }

    #primary-nav {
      justify-items: start;
      align-self: unset;
      padding-block: unset;
    }

    #main-menu {
      min-height: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-block: 0;
    }

    #languages {
      padding: unset;
      border: unset;
    }

    #languages .submenu-indicator {
      display: none;
    }

    #user-menu {
      padding: unset;
      border: unset;
      display: inline-flex;
      align-items: center;
    }

    #user-menu .submenu-indicator {
      display: none;
    }

    .submenu-indicator {
      --icon-size: var(--size-2);
      transform: rotate(90deg);
    }

    bp-global-header-submenu {
      background-color: transparent;
      width: unset;
      height: unset;
      padding: unset;
      opacity: 0;
      transform: translate3d(
        var(--global-header-submenu-tx-closed),
        var(--global-header-submenu-ty-closed),
        0
      );
    }

    #menu-control {
      display: none;
    }

    #give {
      padding-block: unset;
      padding-inline: unset;
    }

    @media (hover: hover) {
      .menu-item:hover {
        background-color: var(--color-interactive-secondary);
      }

      #give .menu-item:hover {
        background-color: var(--color-interactive-secondary-hover);
      }
    }
  }

  /* Embed the animation stylesheet */
  ${animations}
`;
