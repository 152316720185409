import { get } from "../../modules/env";

export interface SearchResultData {
  items: { href: string | null; text: string }[];
}

/**
 * Performs an autocomplete request to the Cludo API and
 * returns parsed results.
 */
export async function performSearch(query: string) {
  const url = get("SEARCH_API_URL");
  const authorization = get("SEARCH_API_TOKEN");

  if (!url || !authorization) {
    throw Error("Missing environment variables required for search");
  }

  const body = JSON.stringify({
    responseType: "JsonHtml",
    template: "SearchContent",
    query,
  });

  const res = await fetch(new URL(url), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${authorization}`,
    },
    body,
  });

  const data: { SearchResult: string } = await res.json();

  return parseSearchResults(data.SearchResult);
}

/**
 * Transforms the HTML returned from Cludo into an object
 * more easily manipulated.
 */
export function parseSearchResults(raw: string): SearchResultData {
  const parser = new DOMParser();

  const doc = parser.parseFromString(raw, "text/html");

  const items = Array.from(doc.querySelectorAll(".suggest-ac a")).map((x) => {
    if (!x.textContent) {
      throw new Error("Unable to parse result from Cludo");
    }

    return {
      text: x.textContent.trim(),
      href: x.getAttribute("href"),
    };
  });

  return {
    items,
  };
}

/**
 * Used in custom converters to append URL parameters.
 */
export function authHrefConverter(value: string | null) {
  if (!value) return value;

  return `${value}?redirect=${globalThis.location.href}`;
}
