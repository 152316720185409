import { LitElement, css, html, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import { authHrefConverter } from "./global-header.helpers";

import type { CurrentUser } from "../../data/user";
import type { GlobalHeaderSubmenu } from "./_submenu";

@customElement("bp-global-header-user-menu")
export class GlobalHeaderUserMenu extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: var(--global-header-submenu-item-gap);
    }

    a {
      appearance: none;
      cursor: pointer;
      box-sizing: border-box;
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      vertical-align: middle;
      gap: var(--size-1);
      background-color: transparent;
      color: currentColor;
      border: none;
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-normal);
      font-family: var(--font-sans);
      line-height: var(--line-height-none);
      -webkit-user-select: none;
      user-select: none;
      text-decoration: none;
      white-space: nowrap;
      padding-block: var(--size-1-5);
      padding-inline: var(--size-3);
      border-radius: var(--radius-md);
      transition: background-color var(--duration-short) var(--ease-in-out);
    }

    a:focus:not(:focus-visible) {
      outline: none;
    }

    a:focus-visible {
      outline-width: 3px;
      outline-color: var(--color-brand-accessible);
    }

    @media (min-width: 1024px) {
      a {
        padding-block: var(--size-1);
        padding-inline: var(--size-1-5);
      }
    }

    @media (hover: hover) {
      a:hover {
        background-color: var(--color-interactive-secondary);
      }
    }
  `;

  @property({
    attribute: "href-sign-in",
    converter: authHrefConverter,
  })
  hrefSignIn: string = "#/";

  @property({
    attribute: "href-sign-out",
    converter: authHrefConverter,
  })
  hrefSignOut: string = "#/";

  @property({ attribute: "href-account" })
  hrefAccount: string = "#/";

  @property({ attribute: "href-help-center" })
  hrefHelpCenter: string = "#/";

  @property({ attribute: "label" })
  label = "";

  @property({ attribute: "label-account" })
  labelAccount = "";

  @property({ attribute: "label-help-center" })
  labelHelpCenter = "";

  @property({ attribute: "label-sign-in" })
  labelSignIn = "";

  @property({ attribute: "label-sign-out" })
  labelSignOut = "";

  @state()
  userInfo: CurrentUser | null = null;

  @query("#user-submenu")
  $submenu?: GlobalHeaderSubmenu;

  setUserInfo(info: CurrentUser | null) {
    this.userInfo = info;
  }

  render() {
    return html`
      <nav>
        <ul>
          ${this.userInfo
            ? html`
                <li>
                  <a href="${this.hrefAccount}"> ${this.labelAccount} </a>
                </li>
              `
            : nothing}

          <li>
            <a href="${this.hrefHelpCenter}"> ${this.labelHelpCenter} </a>
          </li>

          <li>
            ${this.userInfo
              ? html` <a href="${this.hrefSignOut}"> ${this.labelSignOut} </a> `
              : html` <a href="${this.hrefSignIn}"> ${this.labelSignIn} </a> `}
          </li>
        </ul>
      </nav>
    `;
  }
}
