import { LitElement } from "lit";
import { customElement, query, queryAll, state } from "lit/decorators.js";
import { createFocusTrap } from "focus-trap";

import "./_language-picker-label";
import "./_search";
import "./_submenu";
import "./_user-avatar";
import "./_user-menu";
import {
  lockDocumentScrolling,
  polyfillDeclarativeShadowDOM,
  unlockDocumentScrolling,
} from "../../utils/dom";
import { MQL_LG, MQL_MOTION } from "../../constants";
import { getCurrentUser } from "../../data/user";
import { styles } from "./global-header.styles";
import {
  MENU_CLOSE_EVENT_NAME,
  SUBMENU_OPEN_EVENT_NAME,
  SUBMENU_CLOSE_EVENT_NAME,
  SEARCH_OPEN_EVENT_NAME,
  SEARCH_CLOSE_EVENT_NAME,
} from "./global-header.constants";
import { toPx } from "../../utils/units";
import { requestIdleCallback } from "../../utils/timing";

import type { FocusTrap, Options } from "focus-trap";
import type { GlobalHeaderSearch } from "./_search";
import type { GlobalHeaderSubmenu } from "./_submenu";
import type { GlobalHeaderUserMenu } from "./_user-menu";
import type { GlobalHeaderUserAvatar } from "./_user-avatar";

@customElement("bp-global-header")
export class GlobalHeader extends LitElement {
  static styles = styles;

  // Media Query Lists
  #desktopMql = MQL_LG;
  #motionMql = MQL_MOTION;

  // Focus-related properties
  #focusTrap: FocusTrap | null = null;
  #lastFocusedMenuItem: HTMLButtonElement | null = null;

  @state()
  useDesktopLayout = this.#desktopMql.matches;

  @state()
  useAnimations = !this.#motionMql.matches;

  @query("#logo")
  $logo?: HTMLElement;

  @query("#container")
  $container?: HTMLElement;

  @query("#primary-nav")
  $nav?: HTMLElement;

  @query("header")
  $header?: HTMLElement;

  @query("#main-menu")
  $mainMenu?: HTMLElement;

  @query("#menu-open-button")
  $menuOpenButton?: HTMLElement;

  @query("#menu-close-button")
  $menuCloseButton?: HTMLElement;

  @query("bp-global-header-search")
  $search?: GlobalHeaderSearch;

  @queryAll("bp-global-header-submenu")
  $submenus?: NodeListOf<GlobalHeaderSubmenu>;

  @queryAll("[aria-controls]")
  $submenuTriggers?: NodeListOf<HTMLButtonElement>;

  @query("#user-submenu")
  $userSubmenu?: GlobalHeaderSubmenu;

  @query("bp-global-header-user-avatar")
  $userAvatar?: GlobalHeaderUserAvatar;

  @query("bp-global-header-user-menu")
  $userMenu?: GlobalHeaderUserMenu;

  get animationOptions(): KeyframeAnimationOptions {
    return {
      duration: this.useAnimations ? 240 : 0,
      easing: "cubic-bezier(0.2, 0.0, 0, 1.0)",
      fill: "forwards",
    };
  }

  ////////////////
  // LIFECYCLE  //
  ////////////////

  constructor() {
    super();
    polyfillDeclarativeShadowDOM(this);
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener(
      MENU_CLOSE_EVENT_NAME,
      this.handleMenuClose as EventListener,
    );

    this.addEventListener(
      SUBMENU_OPEN_EVENT_NAME,
      this.handleSubmenuOpen as EventListener,
    );

    this.addEventListener(
      SUBMENU_CLOSE_EVENT_NAME,
      this.handleSubmenuClose as EventListener,
    );

    this.addEventListener(
      SEARCH_OPEN_EVENT_NAME,
      this.handleSearchOpen as EventListener,
    );

    this.addEventListener(
      SEARCH_CLOSE_EVENT_NAME,
      this.handleSearchClose as EventListener,
    );

    this.#desktopMql.addEventListener(
      "change",
      this.handleDesktopMediaQueryChange,
    );

    this.#motionMql.addEventListener(
      "change",
      this.handleMotionMediaQueryChange,
    );

    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  disconnectedCallback() {
    window.removeEventListener("resize", this.handleWindowResize);
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("click", this.handleDocumentClick);
  }

  firstUpdated(): void {
    this.checkAuth();
    this.setAnimationAttributes();
    this.reset();

    this.$menuOpenButton?.addEventListener("click", () => {
      this.openMenu();
    });

    this.$menuCloseButton?.addEventListener("click", () => {
      this.closeMenu();
    });
  }

  updated() {
    this.$submenuTriggers?.forEach((x) => {
      x.removeEventListener("click", this.handleMenuButtonClick);
      x.addEventListener("click", this.handleMenuButtonClick);
    });
  }

  //////////////
  // METHODS  //
  //////////////

  async checkAuth() {
    const info = await getCurrentUser();
    this.$userMenu?.setUserInfo(info);
    this.$userAvatar?.setUserInfo(info);
    this.requestUpdate();
  }

  /**
   * Resets elements to their resting states.
   */
  reset() {
    this.setA11yAttributes();
    this.closeMenu();
    this.closeAllSubmenus();
    this.closeSearch();
    this.moveSearch();

    // Set the animation delay to a negative value so that
    // the final state is shown initially.
    this.$header?.style.setProperty("--global-header-animation-delay", "-1s");

    setTimeout(() => {
      // Unset the animation delay value after the intial
      // load so that local scope values are used instead.
      this.$header?.style.removeProperty("--global-header-animation-delay");
    }, 1_000);
  }

  /**
   * Updates element a11y attributes to the appropriate
   * resting state based on layout mode.
   */
  setA11yAttributes() {
    if (this.useDesktopLayout) {
      this.$header?.removeAttribute("data-nav-open");
      this.$container?.removeAttribute("inert");
    } else {
      this.$container?.setAttribute("inert", "");
    }

    this.$search?.removeAttribute("inert");

    this.$submenus?.forEach((x) => {
      x.setAttribute("inert", "");
    });

    this.$submenuTriggers?.forEach((x) => {
      x.setAttribute("aria-haspopup", "true");
      x.setAttribute("aria-expanded", "false");
    });
  }

  /**
   * Sets the `data-animation` attributes that connect CSS
   * animations to specific elements.
   */
  setAnimationAttributes() {
    this.$container?.setAttribute("data-animate", "container");
    this.$mainMenu?.setAttribute("data-animate", "menu");
    this.$logo?.setAttribute("data-animate", "logo");
    this.$search?.setAttribute("data-animate", "search");

    this.$submenus?.forEach(($el) => {
      $el.setAttribute("data-animate", "submenu");
    });

    // TODO make this queried members
    this.shadowRoot?.querySelectorAll(".util-item, #give a").forEach(($el) => {
      $el.setAttribute("data-animate", "util-item");
    });

    this.$mainMenu?.querySelectorAll<HTMLElement>("li").forEach(($el, i) => {
      $el.setAttribute("data-animate", "nav-item");
      $el.style.setProperty("--global-header-animation-delay", `${32 * i}ms`);
    });

    // Fully enable animations once the initial keyframes
    // have settled. This is necessary to avoid a flash of
    // animation on initial page load.
    setTimeout(() => {
      this.$header?.setAttribute("data-animation-ready", "true");
    }, 1_000);
  }

  /**
   * Moves the search element in the DOM so that the
   * tabbing/layout order is appropriate for the state of
   * the matched media.
   */
  moveSearch() {
    if (!this.$search) return;

    if (this.useDesktopLayout) {
      this.$nav?.insertAdjacentElement("afterend", this.$search);
    } else {
      this.$container?.insertAdjacentElement("afterend", this.$search);
    }
  }

  /**
   * Positions the submenu (on desktop) so that it appears
   * underneath the triggering element.
   */
  positionSubMenu($submenu: HTMLElement, $trigger: HTMLElement) {
    const triggerRect = $trigger.getBoundingClientRect();
    const submenuRect = $submenu.getBoundingClientRect();

    const offscreenRight =
      triggerRect.x + submenuRect.width > window.innerWidth + 24;

    const tx = offscreenRight
      ? $trigger.offsetLeft + triggerRect.width - submenuRect.width
      : $trigger.offsetLeft;

    $submenu.style.setProperty("--global-header-submenu-tx-closed", toPx(tx));
    $submenu.style.setProperty("--global-header-submenu-tx-opened", toPx(tx));
  }

  /**
   * Activates a focus trap so that tabbing with a keyboard
   * cycles through focusable elements within the target
   * element.
   */
  activateFocusTrap($element?: HTMLElement | null, options?: Options) {
    // Check that a valid element is provided
    if (!$element || $element.inert) {
      this.#focusTrap = null;
      return;
    }

    this.#focusTrap = createFocusTrap(
      $element,
      Object.assign(
        {
          initialFocus: this.$menuCloseButton,
          setReturnFocus: this.$menuOpenButton,
          allowOutsideClick: true,
          tabbableOptions: {
            getShadowRoot: true,
          },
        },
        options,
      ),
    ).activate();
  }

  /**
   * De-activates the focus trap so that keyboard tabbing
   * resumes with the normal document flow.
   */
  deactivateFocusTrap() {
    this.#focusTrap?.deactivate();
  }

  /**
   * Opens the main menu (on mobile).
   */
  openMenu() {
    if (this.useDesktopLayout) return;

    this.$header?.setAttribute("data-nav-open", "true");

    // Reset the window scroll back to the top.
    requestAnimationFrame(() => {
      window.scroll({ top: -1, left: 0, behavior: "smooth" });
    });

    this.$container?.removeAttribute("inert");
    this.$search?.setAttribute("inert", "true");

    requestIdleCallback(() => {
      this.activateFocusTrap(this.$container);
      lockDocumentScrolling();
    });
  }

  /**
   * Closes the main menu (on mobile).
   */
  closeMenu() {
    if (this.useDesktopLayout) return;

    this.$header?.setAttribute("data-nav-open", "false");
    this.$search?.removeAttribute("inert");

    requestIdleCallback(() => {
      this.deactivateFocusTrap();
      this.closeAllSubmenus();
      this.setA11yAttributes();
      unlockDocumentScrolling();
    });
  }

  /**
   * Opens a specific submenu.
   */
  openSubmenu(id: string) {
    this.closeAllSubmenus({ exclude: [id] });

    // Transition the main menu
    this.$header?.setAttribute("data-submenu-open", "true");

    const $submenu = this.shadowRoot?.querySelector<GlobalHeaderSubmenu>(
      `#${id}`,
    );

    const $trigger = this.shadowRoot?.querySelector<HTMLLIElement>(
      `[aria-controls="${id}"]`,
    );

    this.$container?.scrollTo(0, 0);

    // Position the submenu
    if (this.useDesktopLayout && $submenu && $trigger) {
      this.positionSubMenu($submenu, $trigger);
    }

    $submenu?.removeAttribute("inert");

    // TODO fix focus trap on mobile
    this.activateFocusTrap($submenu, {
      setReturnFocus: $trigger!,
    });

    $trigger?.setAttribute("aria-expanded", "true");

    document.addEventListener("click", this.handleDocumentClick);
  }

  /**
   * Closes a specific submenu.
   */
  async closeSubmenu(id: string, options?: { $trigger?: HTMLElement }) {
    this.$header?.setAttribute("data-submenu-open", "false");
    this.$container?.scrollTo(0, 0);

    this.#lastFocusedMenuItem?.focus();

    const $parent =
      options?.$trigger ??
      this.shadowRoot?.querySelector<HTMLLIElement>(`[aria-controls="${id}"]`);

    // Show the submenu
    const $submenu = this.shadowRoot?.querySelector<GlobalHeaderSubmenu>(
      `#${id}`,
    );

    $submenu?.setAttribute("inert", "");

    if (this.useDesktopLayout) {
      this.deactivateFocusTrap();
    }

    $parent?.setAttribute("aria-expanded", "false");

    document.removeEventListener("click", this.handleDocumentClick);
  }

  /**
   * Closes all the submenus.
   */
  async closeAllSubmenus(options?: { exclude?: string[] }) {
    await Promise.all(
      Array.from(this.$submenus ?? []).map((x) => {
        if (!options?.exclude?.includes(x.id)) {
          return this.closeSubmenu(x.id);
        }

        return Promise.resolve();
      }),
    );
  }

  /**
   * Opens the search box.
   */
  openSearch() {
    if (!this.useDesktopLayout) {
      lockDocumentScrolling();
    }

    // Wait for the focus trap to deactivate
    this.updateComplete.then(() => {
      this.$search?.activate();
      document.addEventListener("click", this.handleDocumentClick);
    });
  }

  /**
   * Closes the search box.
   */
  closeSearch() {
    this.$search?.deactivate();

    // FIXME this should get moved somewhere else. It causes the handler to get removed when the submenu closes
    // document.removeEventListener("click", this.handleDocumentClick);

    unlockDocumentScrolling();
  }

  ///////////////
  // HANDLERS  //
  ///////////////

  handleDesktopMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useDesktopLayout = matches;
    this.reset();
  };

  handleMotionMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useAnimations = !matches;
  };

  handleMenuClose = (e: CustomEvent) => {
    e.stopPropagation();
    this.closeMenu();
  };

  handleMenuButtonClick = (e: MouseEvent) => {
    // Determine the target submenu
    const target = e.currentTarget as HTMLButtonElement;
    const controls = target.getAttribute("aria-controls");
    const $submenu = this.shadowRoot?.querySelector(
      `#${controls}`,
    ) as GlobalHeaderSubmenu;

    if ($submenu.inert) {
      this.openSubmenu($submenu.id);

      // Save the clicked menu item so it can be
      // re-focused after the menu closes. This is
      // required because the menu can be closed my
      // several different triggers, but we only want to
      // return focus to the parent menu item.
      this.#lastFocusedMenuItem = target;
    } else {
      this.closeSubmenu($submenu.id);
    }
  };

  handleSubmenuOpen = (
    e: CustomEvent<{ $trigger?: HTMLElement; id: string }>,
  ) => {
    e.stopPropagation();
    this.openSubmenu(e.detail.id);
  };

  handleSubmenuClose = (e: CustomEvent<{ id: string }>) => {
    e.stopPropagation();
    this.closeSubmenu(e.detail.id);
  };

  handleSearchOpen = (e: CustomEvent) => {
    e.stopPropagation();
    this.openSearch();
  };

  handleSearchClose = (e: CustomEvent) => {
    e.stopPropagation();
    this.closeSearch();
  };

  handleDocumentClick = (e: MouseEvent) => {
    if (!this.useDesktopLayout) return;

    const shouldCloseSubmenus = !Array.from(this.$submenuTriggers ?? []).find(
      (trigger) => e.composedPath().includes(trigger),
    );

    if (shouldCloseSubmenus) {
      this.closeAllSubmenus();
    }

    const shouldCloseSearch =
      this.$search && !e.composedPath().includes(this.$search);

    if (shouldCloseSearch) {
      this.closeSearch();
    }
  };

  handleWindowResize = () => {
    this.closeAllSubmenus();
  };

  handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        if (this.useDesktopLayout) {
          this.closeAllSubmenus();
          this.closeSearch();
        } else {
          this.closeMenu();
        }
        return;
      default:
        return;
    }
  };
}

declare global {
  interface HTMLElementTagNameMap {
    "bp-global-header": GlobalHeader;
  }
}
